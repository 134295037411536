<!--
 * @Date: 2023-10-12 14:19:28
 * @LastEditTime: 2024-06-28 15:23:50
 * @Description: file content
-->
<template>
  <div class="apiDocument full-width">
    <Viewheader ref="navheader" />
    <el-container class="apiDocument-wrap">
      <el-aside
        style="background-color: #fff; height: 100%; border: 1px solid #eee"
      >
        <el-menu
          @select="handleSelect"
          :unique-opened="true"
          :default-openeds="openeds"
          :default-active="curIdx"
        >
          <NavigationItem
            v-for="(menu, i) in adminMenus"
            :key="i"
            :item="menu"
          />
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <MarkDown :markdown="markdown" />
        </el-main>
        <!-- <el-footer>
          <div class="footerDiv">
            <div class="backright">
              <div
                class="textDiv"
                v-if="back.docName"
                @click="() => handleSelect(back.docCode)"
              >
                <i class="el-icon-back"></i>
                <span>上一篇:</span>
                <span>{{ back.docName }}</span>
              </div>
              <div
                class="textDiv rightDiv"
                v-if="right.docName"
                @click="() => handleSelect(right.docCode)"
              >
                <span>下一篇:</span>
                <span> {{ right.docName }}</span>
                <i class="el-icon-right"></i>
              </div>
            </div>
          </div>
        </el-footer> -->
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Viewheader from "../../components/viewheader/viewheader.vue";
import { postDocDetail, postDocItem } from "@/api/md.js";

import NavigationItem from "./component/NavigationItem.vue";
import MarkDown from "./component/Markdown.vue";
export default {
  components: {
    Viewheader,
    NavigationItem,
    MarkDown,
  },
  data() {
    return {
      adminMenus: [],
      markdown: "",
      openeds: [],
      curIdx: "",
      back: {},
      right: {},
    };
  },
  created() {
    this.getDocItem();
  },
  methods: {
    handleSelect(id) {
      let arr = [];
      let inx = 0;
      this.adminMenus.forEach((item) => {
        item.childrenItems.forEach((doc, index) => {
          if (doc.docCode === id) {
            arr = item;
            inx = index;
          }
        });
      });

      if (inx !== 0 && inx !== arr.childrenItems.length - 1) {
        this.back = arr.childrenItems[inx - 1];
        this.right = arr.childrenItems[inx + 1];
      }
      if (inx === 0) {
        this.right = arr.childrenItems[inx + 1];
        this.back = {};
      }
      if (inx === arr.childrenItems.length - 1) {
        this.back = arr.childrenItems[inx - 1];
        this.right = {};
      }

      this.getDocDetail(id);
    },
    async getDocItem() {
      const params = {
        docGroup: "AI",
      };
      const {
        data: { state, message, data },
      } = await postDocItem(params);

      if (state == "0x0008") {
        this.$refs.navheader.logOn();
      } else {
        if (state == "0x0000") {
          this.adminMenus = data?.childrenItems || [];
          console.log(data?.childrenItems, "data?.childrenItems");
          this.adminMenus.findIndex((item) => {
            let idx = item.childrenItems.findIndex((i) => i.fileSize > 0);
            console.log(idx);
            if (idx > -1) {
              this.curIdx = item.childrenItems[idx].docCode;
              return true;
            }
          });
          this.getDocDetail(this.curIdx);
          this.openeds.push(this.adminMenus[0].docCode);
          console.log(this.openeds);
        } else {
          this.$message.error(message);
        }
      }
    },
    async getDocDetail(docCode) {
      if (!docCode) return;
      const params = {
        docCode,
      };
      const {
        data: { state, msg, data },
      } = await postDocDetail(params);
      if (state == "0x0008") {
        this.$refs.navheader.logOn();
      } else {
        if (state == "0x0000") {
          this.markdown = data?.content || "";
        } else {
          this.$message.error(msg);
        }
      }
    },
  },
};
</script>
<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
.apiDocument-wrap {
  height: calc(100vh - 80px);
}
.footerDiv {
  width: calc(100%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.backright {
  width: calc(80%);
  position: relative;
  font-size: 14px;
}
.textDiv {
  color: #333;
  position: absolute;
}
.rightDiv {
  position: absolute;
  right: 0;
}
.footerDiv .backright .textDiv:hover {
  color: #0f40d6;
  cursor: pointer;
}
.textDiv span {
  margin-right: 3px;
  margin-left: 3px;
}
</style>
