<!--
 * @Date: 2023-10-08 14:53:21
 * @LastEditTime: 2023-10-21 16:02:44
 * @Description: file content
-->
<template>
  <div>
    <div v-highlight v-html="htmlContent"></div>
    <div class="markdown-toc">
      <!-- <ul>
        <li v-for="item in toc" :key="item.id">
          <a :href="'#' + item.id">{{ item.text }}</a>
        </li>
      </ul> -->
      <!-- <div ref="reference"></div> -->
    </div>
  </div>
</template>

<script>
import MarkdownIt from 'markdown-it'
import markdownItAnchor from 'markdown-it-anchor'
import markdownItTocDoneRight from 'markdown-it-toc-done-right'
export default {
  props: {
    markdown: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      htmlContent: '',
      toc: [],
    }
  },
  created() {
    // this.$nextTick(() => {
    //   this.renderMarkdown()
    // })
    // this.getMD()
    
  },
  watch: {
    markdown(val) {
      this.renderMarkdown()
    }
  },
  methods: {

    getMD() {
      fetch('http://127.0.0.1:7001/markdown') // 替换 "yourfile.md" 为实际的Markdown文件名
        .then(response => {
          if (response.ok) {
            return response.text(); // 解析响应文本
          } else {
            throw new Error('Failed to fetch Markdown file');
          }
        })
        .then(markdownContent => {
          // 在这里处理Markdown内容，例如将其渲染到页面上
          console.log(markdownContent);
          this.markdown = markdownContent;
          console.log(this.markdown);
            this.$nextTick(() => {
            this.renderMarkdown()
          })
          // 这里可以使用第三方库（例如marked.js）将Markdown渲染为HTML
        })
        .catch(error => {
          console.error('Error:', error);
        });


   
    },
    renderMarkdown() {
      console.log(markdownItAnchor.permalink);
      const that = this;
      const md = new MarkdownIt().use(markdownItAnchor, {
        // permalink: markdownItAnchor.permalink.ariaHidden({
        //   placement: 'after'
        // }),
        permalinkBefore: false//这些有需要就去看文档吧
      })
      .use(markdownItTocDoneRight, {
        containerClass: 'toc',//生成的容器的类名，这样最后返回来的字符串是 <nav class="toc"><nav/>
        containerId: 'toc',//生成的容器的ID，这样最后返回来的字符串是 <nav id="toc"><nav/>
        listType: 'ul',//导航列表使用ul还是ol
        listClass: 'listClass',//li标签的样式名
        linkClass: 'linkClass',//a标签的样式名
        callback: function (html, ) {
            //把目录单独列出来
            // that.$refs.reference.innerHTML = html;
            }
        })
          const result = md.render(this.markdown)
          // console.log(result);
          this.htmlContent = result
          console.log('md.options',md.options);
      }}
    }
</script>

<style scoped>
.markdown-toc {
  margin-top: 20px;
}
</style>
