<!--
 * @Date: 2023-10-12 16:48:47
 * @LastEditTime: 2023-10-23 17:05:15
 * @Description: file content
-->

<template>
  <div>
    <!--叶子级菜单-->
    <template
      v-if="item && item.childrenItems && item.childrenItems.length === 0"
    >
      <el-menu-item :key="item.id" :index="item.docCode">
        {{ item.docName }}
      </el-menu-item>
    </template>
    <!--父级菜单-->
    <el-submenu
      v-else
      :index="item.docCode"
      style="text-align: left"
      :disabled="item.childrenItems.length == 0"
    >
      <span slot="title" style="font-size: 17px">
        <i :class="item.iconCls"></i>
        {{ item.docName }}
      </span>
      <template v-for="child in item.childrenItems">
        <navigation-item
          v-if="child.childrenItems && child.childrenItems.length > 0"
          :key="child.id"
          :item="child"
        />
        <el-menu-item
          :disabled="child.fileSize == 0"
          v-else
          :key="child.docCode"
          :index="child.docCode"
        >
          <i :class="child.icon"></i>
          {{ child.docName.split(".md")[0] }}
        </el-menu-item>
      </template>
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: "NavigationItem",
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {};
  },
};
</script>
