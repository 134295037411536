/*
 * @Date: 2023-10-21 13:53:11
 * @LastEditTime: 2023-10-21 14:01:37
 * @Description: file content
 */
import request from "@/router/axios";
export const postDocDetail = (data) => {
  return request({
    url: "/api/doc/detail",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};
export const postDocItem = (data) => {
  return request({
    url: "/api/doc/items",
    method: "post",
    headers: {
      token: localStorage.getItem("tokenKey")
        ? localStorage.getItem("tokenKey")
        : "",
    },
    data: {
      ...data,
    },
  });
};
// export const apiUri = "http://192.168.48.36"
export const apiUri = "/api";
